import { Order } from '@local/web-design-system';
import { LatLngBounds, latLng, latLngBounds } from 'leaflet';
import { isEqual } from 'lodash-es';
import store from 'store';
import { CLIENT_SIDE_PAGINATION_LIMIT, DEFAULT_FILTERS } from 'state-domains/constants';
import { stringSort } from 'state-domains/utils';
import { formatDateToDisplay } from 'src/utilities/formatters';

import { Project } from './types';

export const MAX_DRILLHOLES = 2000;

export const filterProjects = (
    projectList: Project[],
    searchTerm = '',
    offset = 0,
    limit = CLIENT_SIDE_PAGINATION_LIMIT,
    searchAllFields = true,
): [Project[], number] => {
    const sortedPinnedProjects = stringSort({
        objectList: projectList,
        key: 'pinned',
        order: Order.DESCENDING,
    });

    const filtered = filterProjectsBySearchTerm(sortedPinnedProjects, searchTerm, searchAllFields);
    const totalCount = filtered.length;
    return [filtered.splice(offset, limit), totalCount];
};
const filterProjectsBySearchTerm = (
    projectList: Project[],
    searchTerm = '',
    searchAllFields = true,
): Project[] => {
    if (!searchTerm) {
        return projectList;
    }
    const searchKeysLowerCase = searchTerm.toLowerCase().split(' ');

    return projectList.filter((project: Project) => {
        const { name, description, updatedAt, location } = project;

        let matchResult = true;

        searchKeysLowerCase.forEach((searchKeyLowerCase) => {
            const matchesName = name && name.toLowerCase().includes(searchKeyLowerCase);
            let matches: boolean | undefined = !!matchesName;
            if (searchAllFields) {
                const matchesDescription =
                    description && description.toLowerCase().includes(searchKeyLowerCase);
                const matchesLocation =
                    location && location.toLowerCase().includes(searchKeyLowerCase);
                const matchesLastUpdated =
                    updatedAt &&
                    formatDateToDisplay(updatedAt)
                        .toString()
                        .toLowerCase()
                        .includes(searchKeyLowerCase);

                matches =
                    matchesName || matchesLocation || matchesDescription || matchesLastUpdated;
            }

            if (!matches) {
                matchResult = false;
            }
        });
        return matchResult;
    });
};

export const CACHED_OVERVIEW_FILTERS = 'cached_overview_filters';
export const CACHED_MAP_BOUNDS = 'cached_overview_map_bounds';

export function getFiltersForOverviewCollars(id: string, userId: string, subscriptionId: string) {
    const allFilters = store.get(`${CACHED_OVERVIEW_FILTERS}_${userId}_${subscriptionId}`, {});
    if (!!allFilters && !!id) {
        return allFilters[id] ?? DEFAULT_FILTERS();
    }
    return DEFAULT_FILTERS();
}

export function updateOverviewCollarsFilters(
    id: string,
    userId: string,
    subscriptionId: string,
    newFilters: Record<string, any[]>,
) {
    const allFilters = store.get(`${CACHED_OVERVIEW_FILTERS}_${userId}_${subscriptionId}`, {});
    const dHoleFilters = allFilters ?? {};
    const updated = {
        ...dHoleFilters,
        [id]: newFilters,
    };
    store.set(`${CACHED_OVERVIEW_FILTERS}_${userId}_${subscriptionId}`, { ...updated });
}

export function isFilterSet(id: string, userId: string, subscriptionId: string) {
    const filters = getFiltersForOverviewCollars(id, userId, subscriptionId);
    return !isEqual(filters, DEFAULT_FILTERS());
}

export const DEFAULT_OVERVIEW_MAP_BOUNDS = latLngBounds(latLng(-90, -180), latLng(90, 180));
export const DEFAULT_OVERVIEW_ZOOM_LEVEL = 2;

export function getOverviewMapBounds(
    id: string,
    userId: string,
    subscriptionId: string,
): LatLngBounds {
    return getSavedMapBounds(id, userId, subscriptionId) ?? DEFAULT_OVERVIEW_MAP_BOUNDS;
}
export function getSavedMapBounds(
    id: string,
    userId: string,
    subscriptionId: string,
): LatLngBounds | undefined {
    const bounds = store.get(`${CACHED_MAP_BOUNDS}_${userId}_${subscriptionId}`, {});
    if (!!bounds && !!id && !!bounds[id]) {
        const { northEast, southWest } = bounds[id];
        return latLngBounds(
            latLng(southWest.lat, southWest.lng),
            latLng(northEast.lat, northEast.lng),
        );
    }
    return undefined;
}

export function getOverviewMapZoom(id: string, userId: string, subscriptionId: string): number {
    const bounds = store.get(`${CACHED_MAP_BOUNDS}_${userId}_${subscriptionId}`, {});
    if (!!bounds && !!id && !!bounds[id]) {
        const { zoomLevel } = bounds[id];
        return zoomLevel;
    }
    return DEFAULT_OVERVIEW_ZOOM_LEVEL;
}

export function updateOverviewMapBounds(
    id: string,
    userId: string,
    subscriptionId: string,
    newBounds: LatLngBounds,
    zoom: number,
) {
    const allBounds = store.get(`${CACHED_MAP_BOUNDS}_${userId}_${subscriptionId}`, {});
    const updated = {
        ...allBounds,
        [id]: {
            northEast: newBounds.getNorthEast(),
            southWest: newBounds.getSouthWest(),
            zoomLevel: zoom,
        },
    };
    store.set(`${CACHED_MAP_BOUNDS}_${userId}_${subscriptionId}`, { ...updated });
}
